/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  font-size: inherit;
  font-weight: normal;
  text-align: left;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ul[class] {
  list-style: none;
}
ol li {
  padding-left: 0.5em !important;
}
li.disc {
  list-style: disc;
  margin-left: 1em !important;
  padding-left: 0.5em !important;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Remove default link colors */
a {
  color: inherit;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}